body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ant-dropdown-menu-item {
  height: 45px;
  background-color: rgb(115, 196, 223);
}

.ant-dropdown-menu {
  padding: 0px 0px !important;
}

.Nav_children {
  color: #fff;
  height: 26px;
  line-height: 26px;
}

.Nav_children:hover {
  color: rgb(115, 196, 223) !important
}

.NodeText {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  /* 设置文本超出部分省略 */
  text-overflow: ellipsis;
}

.ant-modal-wrap {
  min-width: 1200px !important;
}
.vjs-big-play-button{
  top:50% !important;
  left:50% !important;
}