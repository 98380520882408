.main {
    /* background:red !important */
    background: url(../image/sybjt.png);
    width: 100%;
    height: 100%;
    background-size: 100% 100%;
    position: relative;
}

.whale1,
.whale2,
.whale3,
.whale4,
.whale5,
.whale6 {
    width: 240px;
}

.whale2 {
    position: absolute;
    right: 10%;
    bottom: 15%;
}

/* .whale3 {
    position: absolute;
    right: 5%;
    top: 30%;
} */

.whale1 {
    position: absolute;
    left: 6%;
    bottom: 18%;
}

.whale4 {
    position: absolute;
    right: 27%;
    top: 29%;
}

.whale5 {
    position: absolute;
    left: 38%;
    bottom: 10%;
}

.whale6 {
    position: absolute;
    left: 22%;
    top: 24%;
}

/* a img:hover {
    width: 350px;
} */

/* .whale1,
.whale2,
.whale3, */
.main div img:hover {
    animation: fadenum 2s;
}

@keyframes fadenum {

    100% {
        transform: scale(1.1, 1.1);
    }

    75% {
        transform: scale(1.2, 1.2);
    }

    50% {
        transform: scale(1.3, 1.3);
    }

    25% {
        transform: scale(1.4, 1.4);
    }


}

.flowersLeft {
    width: 150px;
    position: absolute;
    left: 5%;
    bottom: 9%;

}

.flowersRight {
    width: 160px;
    position: absolute;
    right: 4%;
    bottom: 8%;
}


.logo {
    width: 60px;
    position: absolute;
    left: 9%;
    top: 28%;
}


.ant-upload-list {
    width: 300px;
}

.loginContent {
    position: absolute;
    top: 0px;
    left: 0px;
    background: url(../image/sybjt.png);
    width: 100%;
    height: 100vh;
    background-size: 100% 100%;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
}

.courseContent {
    width: 100%;
    min-width: 1200px;
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    z-index: 10;
    overflow: auto;
    background: url(../image/sybjt.png);
    display: flex;
    justify-content: center;
    flex-direction: row;
    padding-top: 98px;
}

.liveContent {
    transition: all 0.6s;
    cursor: pointer;
}

.liveContent:hover {
    transform: scale(1.15)
}

.courseSwitch {
    background: url(../image/courseSwitch.png);
    background-position: -306px -4px;
}

.courseSwitch2 {
    background: url(../image/courseSwitch2.png);
    background-position: -93px -4px;
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(-90deg);
    }
}

.button_r {
    background: url(../image/button.png);
}

/*隐藏滚动条*/
/* ::-webkit-scrollbar {
    display: none;
} */

div::-webkit-scrollbar {
    width: 8px;
    background-color: #f1f1f1;
}

div::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #f1f1f1;
}

div::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #aaa;
}